<template>
  <div>
    <v-container>
      <snackbar :attr="snackbar" />
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="6" lg="5">
          <v-card class="elevation-2 mt-8">
            <v-toolbar flat class="pt-4">
              <v-spacer></v-spacer>
              <v-toolbar-title> Let's see if we can find you.</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom> </v-tooltip>
            </v-toolbar>
            <v-card-text>
              <v-form ref="form" v-model="valid">
                <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  label="Email..."
                  name="email"
                  autocomplete="email"
                  type="text"
                  required
                  ><vue-fontawesome
                    icon="user"
                    color="grey"
                    slot="prepend"
                    class="mt-1"
                /></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="checkEmail()" :disabled="!valid"
                ><vue-fontawesome :icon="['far', 'check']" class="mr-1" />Check
                Email</v-btn
              >
              <!-- <v-btn
                  elevation="0"
                  dark
                  class="primary-btn mr-4"
                  @click="checkEmail()"
                  :disabled="!valid"
                  ><vue-fontawesome
                    :icon="['far', 'check']"
                    class="mr-1"
                  />Check Email</v-btn
                >
              </div> -->
            </v-card-actions>
          </v-card>
          <v-expand-transition>
            <div v-show="emailFound">
              <v-card elevation="0" class="mt-6">
                <v-toolbar flat class="pt-4">
                  <v-spacer></v-spacer>
                  <v-toolbar-title
                    >Great! We found your email address.</v-toolbar-title
                  >
                  <v-spacer></v-spacer>
                  <v-tooltip bottom> </v-tooltip>
                </v-toolbar>
                <v-card-text class="text-center">
                  <p>Set a new secure password...</p>
                  <v-btn
                    elevation="0"
                    dark
                    class="primary-btn mr-4"
                    @click="requestRegister"
                    ><vue-fontawesome
                      :icon="['fas', 'envelope']"
                      class="mr-1"
                    />Send Email</v-btn
                  >
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </div>
          </v-expand-transition>
          <v-expand-transition>
            <div v-show="emailSent">
              <v-card elevation="0" class="mt-6">
                <v-toolbar flat class="pt-4">
                  <v-spacer></v-spacer>
                  <v-toolbar-title>Please check your inbox.</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-tooltip bottom> </v-tooltip>
                </v-toolbar>
                <v-card-text class="text-center">
                  <p>Password reset has been sent.</p>
                  <router-link to="/login">
                    <v-btn elevation="0" dark class="primary-btn mr-4"
                      ><vue-fontawesome
                        :icon="['fas', 'sign-in-alt']"
                        class="mr-1"
                      />Login</v-btn
                    >
                  </router-link>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </div>
          </v-expand-transition>
          <v-expand-transition>
            <div v-show="gotoLogin">
              <v-card elevation="0" class="mt-6">
                <v-toolbar flat class="pt-4">
                  <v-spacer></v-spacer>
                  <v-toolbar-title
                    >You already have an active user login.</v-toolbar-title
                  >
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text class="text-center">
                  <router-link to="/login">
                    <v-btn elevation="0" dark class="primary-btn mr-4"
                      ><vue-fontawesome
                        :icon="['fas', 'sign-in-alt']"
                        class="mr-1"
                      />Login</v-btn
                    >
                  </router-link>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </div>
          </v-expand-transition>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import UserService from '@/services/UserService'
import Snackbar from '@/components/Snackbar.vue'

export default {
  name: 'Lookup',
  components: { Snackbar },
  data: () => ({
    snackbar: {
      model: false,
      color: '',
      message: '',
      icon: ['fas', 'exclamation-triangle'],
      timeout: 3000,
    },
    emailFound: false,
    emailNotFound: false,
    emailSent: false,
    gotoLogin: false,
    propertyId: '',
    hasError: false,
    people: [],
    // loggedIn: '333',
    valid: false,
    forgotValid: false,
    firstName: '',
    lastName: '',
    email: '',
    emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) => /.+@.+/.test(v) || 'E-mail must be valid',
    ],
  }),
  methods: {
    reset() {
      this.valid = false
      this.$refs.form.reset()
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
    validateForm() {
      this.$refs.form.validate()
    },
    resetPassword() {
      this.forgotValid = false
      this.$refs.form.reset()
    },
    resetForgotValidation() {
      this.$refs.forgot.resetValidation()
    },
    validateForgotForm() {
      this.$refs.forgot.validate()
    },
    async checkEmail() {
      try {
        const data = {
          email: this.email,
        }
        const requestResult = await UserService.lookup(data)
        if (!requestResult) {
          return
        }
        var i
        this.people = requestResult.data.property.people
        // console.log(this.people)
        for (i = 0; i < this.people.length; i++) {
          if (this.people[i].email == this.email) {
            this.firstName = this.people[i].firstName
            this.lastName = this.people[i].lastName
          }
        }
        // if (requestResult) {

        // }
        this.emailFound = true
        this.gotoLogin = false
        this.emailSent = false
        this.propertyId = requestResult.data.property.id
        this.snackbar.model = true
        this.snackbar.message = 'Email found.'
        this.snackbar.color = 'green'
        this.snackbar.icon = ['far', 'check']
        // console.log(requestResult)
      } catch (err) {
        if (err.response.status === 409) {
          this.emailFound = false
          this.gotoLogin = true
          this.emailSent = false
        }
        // console.log(err.response.status)
        this.snackbar.model = true
        this.snackbar.message = err.response.data.error
        // this.snackbar.message = 'Email not found.'
        this.snackbar.color = 'red'
        this.snackbar.icon = ['fas', 'exclamation-triangle']
        // swal('Error', 'Something Went Wrong', 'error')
        // console.log(err.response.data)
      }
    },
    async requestRegister() {
      try {
        const data = {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          property: this.propertyId,
        }
        const requestResult = await UserService.requestRegister(data)
        if (!requestResult) {
          return
        }
        this.emailFound = false
        this.gotoLogin = false
        this.emailSent = true
        this.snackbar.model = true
        this.snackbar.message = 'Success. Please check your email.'
        this.snackbar.color = 'green'
        this.snackbar.icon = ['far', 'check']
        // console.log(requestResult)
      } catch (err) {
        this.snackbar.model = true
        this.snackbar.message = err.response.data.msg
        // this.snackbar.message = 'Email not found.'
        this.snackbar.color = 'red'
        this.snackbar.icon = ['fas', 'exclamation-triangle']
        // swal('Error', 'Something Went Wrong', 'error')
        // console.log(err.response.data)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.v-btn {
  font-weight: 500;
  letter-spacing: 0;
  justify-content: center;
  outline: 0;
  position: relative;
  text-decoration: none;
  text-indent: 0.0892857143em;
  text-transform: none;
}
</style>
